<template>
  <div class="container">
    <div class="box" v-if="show">
      <!-- <h3>{{title}}</h3> -->
      <el-upload
        drag
        ref="upload"
        :multiple="false"
        class="upload-item"
        :action="uploadUrl"
        :headers="headers"
        :data="fileData"
        :show-file-list="false"
        :on-success="handleSuccess"
        :on-progress="handleProgress"
        :on-change="changeFile"
        :before-upload="beforeUpload"
        :auto-upload="false"
        :http-request="(file) => { return handleUpload(file)}"
      >
        <div slot="trigger">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或点击上传</div>
          <el-button size="medium" type="primary">上&nbsp;传</el-button>
        </div>
      </el-upload>
    </div>
  </div>
</template>

<script>
import md5 from "js-md5"
import axios from 'axios'
const SIZE = 150 * 1024 * 1024
export default {
  name: 'Interpage',
  components: {},
  data () {
    return {
      show: true,
      // title: "文件上传",
      qrcodeUrl: process.env.VUE_APP_BASE_API + "/common/file/add-qrcode-file",
      uploadUrl: process.env.VUE_APP_BASE_API + "/common/upload-file/upload-slice-file", // 切片上传
      // 定义表头携带参数
      headers: {
        fromtype: 2,
        "app-version": "",
        "request-time": "",
        "request-sign": "",
      },
      // 上传时附带的额外参数
      fileData: {
        is_unzip: 0,
        module: "file,url",
      },
      sliceFiles: true, // 是否切片上传
      // 切片上传
      sliceFileData: {}, // 存放要切片文件信息
      section: [], // 用于存放加工好的文件切片列表
      requestSize: 0, // 已上传的大小
      requestPercentage: 0, // 已上传的进度
      hashPercentage: 0, // 存放hash生成进度
      returnValue: [], // 上传切片的返回值
      pageloading: null, // 页面加载
      code_key: "",
    }
  },
  computed: {},
  created () {
    let url = window.location.href
    // 获取参数部分
    let params = url.split("?")[1]
    // 将参数部分转换为对象
    let paramsObj = {}
    if (params) {
      let paramsArr = params.split("&")
      for (let i = 0; i < paramsArr.length; i++) {
        let param = paramsArr[i].split("=")
        paramsObj[param[0]] = param[1]
      }
    }
    this.code_key = paramsObj?.code_key ?? ""
    this.headers.Authorization = "Bearer " + paramsObj?.token ?? ""
    this.headers.identifier = Number(paramsObj?.identifier) ?? null
    this.headers.schoolid = Number(paramsObj?.schoolid) ?? null
    if (!this.code_key) {
      this.show = false
      this.$message.warning('二维码已失效/过期, 请重新扫码!')
      return false
    }
    this.setHeaders() // 配置上传默认请求头
  },
  mounted () { },
  methods: {
    async setQrcodeFile (data) {
      let params = {
        code_key: this.code_key,
        ...data
      }
      try {
        let res = await axios({ url: this.qrcodeUrl, method: 'post', headers: this.headers, data: params }) // 上传文件
        if (res.data && res.data.code === 0) {
          this.$message.success('文件上传成功!')
        } else {
          this.$message.error(res.data.message)
        }
      } catch (error) { }
    },
    // 配置上传默认请求头
    setHeaders () {
      const date = new Date()
      const c_time = Math.trunc(date.getTime() / 1000)
      const sign_time = c_time.toString()
      const app_version = "v1.0"
      this.headers["app-version"] = app_version
      this.headers["request-time"] = c_time
      this.headers["request-sign"] = md5(md5(sign_time) + app_version)
    },
    /**************************** 上传前准备 ****************************/
    // 文件状态改变时(添加文件、上传成功和上传失败)
    changeFile (file, fileList) {
      // console.log("抓取文件==>",file, fileList);
      // 判断是否切片上传 file.response 是选中文件上传返回数据
      if (this.sliceFiles) {
        // 切片上传
        this.sliceFileData = { file: null, hash: null } // 先清空
        this.sliceFileData.file = file.raw // 再赋值，获取上传文件
        this.fileChunks() // 文件切片
      }
    },
    // 上传文件之前，返回false或 Promise且被reject 则 禁止上传
    beforeUpload (file) {
      const isSize = file.size / 1024 / 1024 < 500
      if (!isSize) {
        this.$message.error("上传文件大小不能超过 500MB!")
        return false
      }
    },
    /**************************** 普通上传：文件走组件自带的上传方法 ****************************/
    // 文件上传中……
    handleProgress (event, file, fileList) {
      // console.log("文件上传中==>",event, file, fileList);
      // this.$store.commit(
      //   "stopwatch/SET_PERCENTAGE",
      //   Math.floor(event.percent)
      // ) // 在vuex中存储进度条进度：变换
    },
    // 文件上传成功
    handleSuccess (res, file, fileList) {
      // console.log("文件上传成功==>",res, file, fileList);
      // 判断上传文件类型
      if (this.imgsrc) {
        // 图片
        this.imageUrl = URL.createObjectURL(file.raw)
      }
      /* 父组件回显值 */
      const FileArr = [] // 定义空数组接收返回值
      if (res.code === 0) {
        const params = {
          path: file.response.data[0].path,
          name: file.name,
          size: file.size,
          ext: file.response.data[0].ext,
          url: file.response.data[0].url,
        }
        FileArr.push(params)
        this.$emit("setUrl", JSON.stringify(FileArr)) // 向父组件传参
      }
    },
    /**************************** 大文件切片上传：切片走自定义上传方法 ****************************/
    // 生成文件切片
    createFileChunk (file, size = SIZE) {
      const fileChunkList = []
      let cur = 0
      while (cur < file.size) {
        fileChunkList.push({
          file: file.slice(cur, cur + size),
        })
        cur += size
      }
      return fileChunkList
    },
    // 生成文件hash
    calculateHash (fileChunkList) {
      return new Promise((resolve) => {
        this.sliceFileData.worker = new Worker("hash.js")
        this.sliceFileData.worker.postMessage({ fileChunkList })
        this.sliceFileData.worker.onmessage = (e) => {
          const { percentage, hash } = e.data
          // 可以用来显示进度条
          this.hashPercentage = Math.trunc(percentage)
          if (hash) {
            resolve(hash)
          }
        }
      })
    },
    // 大文件切片处理
    async fileChunks () {
      if (!this.sliceFileData.file) return
      // 切片生成
      const fileChunkList = this.createFileChunk(this.sliceFileData.file)
      // console.log("切片生成", fileChunkList);
      // hash生成
      let hash = await this.calculateHash(fileChunkList)
      // console.log("生成hash", hash);
      this.sliceFileData.hash = hash
      let date = new Date().getTime()
      this.section = fileChunkList.map(({ file }, index) => ({
        chunk: file,
        size: file.size,
        hash: this.sliceFileData.hash + date, // 这里的hash为文件名 + 切片序号，也可以用md5对文件进行加密获取唯一hash值来代替文件名
        num: 1 + index,
        sum: fileChunkList.length,
      }))
      // console.log("生成切片文件列表",this.section);
      this.submitUpload() // 这里需要启动手动上传，自动上传会在文件在切片之前执行，造成上传文件出错！
    },
    // 手动上传到服务器
    submitUpload () {
      this.$refs.upload.submit() // 触发上传
      this.$refs.upload.clearFiles() // 清空上传数据
    },
    // 覆盖默认的上传行为，可以自定义上传的实现,上传切片
    async handleUpload (files) {
      // console.log("覆盖默认的上传==>",file);
      const that = this
      // 接收切片文件
      let requestList = that.section.map(
        ({ chunk, hash, num, sum, size }) => {
          // 构造formData
          // console.log("上传切片",chunk，hash，size,num,sum，this.sliceFileData.file.name);
          const formData = new FormData()
          formData.append("file", chunk)
          formData.append("size", size)
          formData.append("hash", hash)
          formData.append("name", this.sliceFileData.file.name)
          formData.append("current_count", num)
          formData.append("total_count", sum)
          formData.append("is_unzip", 0)
          formData.append("module", "file,url")
          return { formData }
        }
      )
      // 上传切片并执行三次
      let res = await that.whileRequest(requestList) // 生成文件切片列表循环上传
      if (res.length === 0) {
        that.handleSliceSuccess() // 上传成功
      } else {
        that.$message.error("上传失败，重试中……")
        let res2 = await that.whileRequest(res)
        if (res2.length === 0) {
          that.handleSliceSuccess() // 上传成功
        } else {
          let res3 = await that.whileRequest(res2)
          that.$message.error("重传失败，再次重试……")
          if (res3.length === 0) {
            that.handleSliceSuccess() // 上传成功
          } else {
            that.isUploadingFiles = false // 解开上传禁用
            that.isGetList = false // 解开选择按钮禁用
            this.pageloading.close() // 取消加载动画
            // this.$store.commit("stopwatch/SET_SHOW", false) // 在vuex中存储进度条状态：隐藏
            that.$message.error("重试失败，请检查文件并重新上传！")
          }
        }
      }
    },
    // 依次上传文件切片
    async whileRequest (list) {
      this.pageloading = this.$loading({
        lock: true,
        text: "文件正在上传，请勿关闭浏览器或刷新页面……",
        spinner: "",
        background: "rgba(0, 0, 0, 0.8)",
        customClass: 'file-loading',
      })
      let i = 0
      let newArr = []
      let newObj = {}
      while (i < list.length) {
        //console.log("循环上传" + list.length + "个切片中的第" + (i + 1) + "个")
        let { formData } = list[i]
        // 提交切片并返回值
        let res = await this.request({
          data: formData,
        })
        // console.log("切片上传返回数据==>",res);
        let { code, data } = JSON.parse(res.data)
        if (code === 0) {
          newObj = Object.assign({}, data) // 已上传切片文件大小
          newObj.name = this.sliceFileData.file.name // 切片文件名称
          this.requestSize += data.size // 已上传切片文件大小
          // 计算上传进度
          this.requestPercentage =
            (this.requestSize / this.sliceFileData.file.size) * 100 // 进度条进度
          // this.$store.commit(
          //   "stopwatch/SET_PERCENTAGE",
          //   Math.floor(this.requestPercentage)
          // ) // 在vuex中存储进度条进度：变换
          this.returnValue.unshift(newObj) // 添加已上传切片到返回
          this.pageloading.close()
        } else {
          newArr.push({ formData: formData })
        }
        i++
      }
      return newArr
    },
    // ajxs 上传
    request ({ data }) {
      this.setHeaders() // 配置上传默认请求头
      return new Promise((resolve) => {
        const xhr = new XMLHttpRequest()
        xhr.open("post", this.uploadUrl)
        Object.keys(this.headers).forEach((key) =>
          xhr.setRequestHeader(key, this.headers[key])
        )
        xhr.send(data)
        xhr.onload = (e) => {
          resolve({
            data: e.target.response,
          })
        }
      })
    },
    // 切片文件全部上传成功
    handleSliceSuccess () {
      this.requestSize = 0 // 上传成功后进度归零
      const file = this.returnValue[0] // 获取上传最后一块切片返回的数据
      const params = {
        name: file.name,
        ext: file.ext,
        size: file.size,
        url: file.url,
      }
      this.setQrcodeFile(params)
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  background-image: url('../../assets/upload/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.box {
  position: relative;
  width: 1200px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 180px;
}
::v-deep .el-upload-dragger {
  height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba($color: #fff, $alpha: 0.9);
  .el-icon-upload {
    margin: 0px;
    color: #27bddf;
  }
  .el-upload__text {
    margin: 10px 0px 32px;
    color: #333;
    font-size: 16px;
    font-weight: 500;
  }
  .el-button--primary {
    background-color: #27bddf;
    border-color: #27bddf;
    padding: 10px 35px;
    font-size: 14px;
  }
}
</style>
